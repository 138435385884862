import React from 'react'

import Layout from '../components/Layout.js'

import styled from 'styled-components'

import Img from 'gatsby-image'
import FadeIn from '../components/FadeIn'
import PageTitle from '../components/PageTitle'
import { rhythm, media } from '../settings/styles'
import ContactForm from '../components/ContactForm'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { colors } from '../settings/styles'

const Content = styled.div`
  text-align: center;
  /* font-weight: bold; */
  text-align: center;
  margin-bottom: ${rhythm(6)};
  color: ${colors.brandText};

  ${media.phone`
    margin-bottom: ${rhythm(3)};      
  `};
  a {
    color: inherit;
  }
`

const ContactPage = props => {
  const { data } = props
  return (
    <Layout location={props.location}>
      <Helmet>
        <title>Contact | Wow my day</title>
      </Helmet>
      <FadeIn>
        <Img
          fluid={
            data.contact.relationships.field_image.localFile.childImageSharp
              .fluid
          }
          alt=""
        />
        <PageTitle>{data.contact.title}</PageTitle>
        <div className="l-constrained">
          <Content
            dangerouslySetInnerHTML={{
              __html: data.contact.body.processed,
            }}
          />
          <ContactForm title={data.contact.field_form_title} />
        </div>
      </FadeIn>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query pageQuery {
    contact: nodeContact {
      id
      title
      field_form_title
      body {
        processed
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 256) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
