import React from 'react'
import styled, { keyframes } from 'styled-components'

import { media } from '../settings/styles'
import background from '../img/modal.jpg'
import closer from '../img/modal-closer.svg'

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 5000;
  display: flex;
  justify-content: center;
`

const popIn = keyframes`
  from {
    transform: scale(.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`

const Box = styled.div`
  animation: 500ms ${popIn};
  margin: auto;
  background: url(${background});
  background-size: cover;
  position: relative;
  padding: 8rem 10rem;

  ${media.wide`
    padding: 5rem 7rem;
  `};

  ${media.desktop`
  padding: 4rem 3rem;
  `};

  ${media.tablet`
  width: 90%;
  `};
`

const Content = styled.div`
  width: 700px;
  padding: 3rem;
  background: #fff;
  text-align: center;
  font-weight: 300;

  font-size: 1.4rem;
  color: #000000;
  line-height: 1.33;

  ${media.wide`
    width: 600px;
  `};

  ${media.desktop`
    width: 500px;
  `};

  ${media.tablet`
  width: 100%;
  `};
`

const Closer = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: transparent;
  appearance: none;
  border-radius: 0;
  border: 0;
  cursor: pointer;
`

const ContactThanksModal = ({ onCloseClick }) => (
  <Modal>
    <Box>
      <Closer>
        <img onClick={onCloseClick} src={closer} alt="Close this" />
      </Closer>
      <Content>
        <strong>
          Wow! <br />
          Thanks for contacting us. <br />
        </strong>
        We’ll get back to you as soon as we arrive from the flower market.
      </Content>
    </Box>
  </Modal>
)
export default ContactThanksModal
