import React, { Component } from 'react'

import styled from 'styled-components'

import { colors } from '../settings/styles'

import ContactThanksModal from './ContactThanksModal'

import Recaptcha from 'react-google-recaptcha'
const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

const FormTitle = styled.h2`
  font-family: Georgia;
  font-weight: bold;
  font-size: 1.875rem;
  color: ${colors.brandText};
  text-align: center;
  line-height: 1;
  margin-bottom: 1em;
`
const Form = styled.form`
  max-width: 800px;
  margin: 0 auto;
  color: ${colors.brandText};

  > div {
    margin-bottom: 2rem;
  }
  margin-bottom: 100px;

  input,
  textarea {
    font-family: inherit;
    color: inherit;
    font-size: 16px;
    padding: 0.2em 0.5em;
    background: transparent;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    border: 1px solid #d8d8d8;
    max-width: 100%;
    min-width: 100%;
    min-height: 2em;

    &:focus {
      outline: none;
      border-color: ${colors.brandText};
    }

    &::placeholder {
      color: inherit;
      opacity: 0.6;
    }
  }

  input[type='text'],
  input[type='email'] {
    border-top: 0;
    border-right: 0;
    border-left: 0;
  }

  footer {
    text-align: center;

    button {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0.1em;
      text-align: center;
      font-family: inherit;
      background: ${colors.brandText};

      border: 0;
      border-radius: 0;

      padding: 1em 5em;
    }
  }
`

const Error = styled.div`
  border: 1px solid red;
  padding: 1em;
  background: #ffdddd;
  margin-bottom: 2rem;
`
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends Component {
  state = {
    success: false,
    modalOpen: false,
    name: '',
    email: '',
    message: '',
  }

  onModalCloseClick = () => {
    this.setState({ modalOpen: false })
  }

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.state['g-recaptcha-response']) {
      return this.setState({ error: true })
    }

    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contactv2', ...this.state }),
    })
      .then(() =>
        this.setState({
          modalOpen: true,
          name: '',
          email: '',
          message: '',
          'g-recaptcha-response': null,
        })
      )
      .catch(error => this.setState({ error: true }))
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { name, email, message } = this.state
    const { title } = this.props

    return (
      <div>
        {this.state.modalOpen && (
          <ContactThanksModal onCloseClick={this.onModalCloseClick} />
        )}
        <FormTitle>{title}</FormTitle>
        {this.state.error && (
          <Error>
            Something went wrong trying to send your message. Please try again
            or use the mailaddress above.
          </Error>
        )}

        <Form
          name="contactv2"
          method="POST"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={this.handleSubmit}
        >
          <div>
            <input
              onChange={this.handleChange}
              value={name}
              required="true"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
            />
          </div>
          <div>
            <input
              onChange={this.handleChange}
              value={email}
              required="true"
              type="email"
              name="email"
              id="email"
              placeholder="E-mail"
            />
          </div>
          <div>
            <textarea
              onChange={this.handleChange}
              value={message}
              required="true"
              name="message"
              id="message"
              cols="30"
              rows="5"
              placeholder="Message"
            />
          </div>
          <div>
            {RECAPTCHA_KEY && (
              <Recaptcha
                ref="recaptcha"
                sitekey={RECAPTCHA_KEY}
                onChange={this.handleRecaptcha}
              />
            )}
          </div>
          <footer>
            <button type="submit">Send</button>
          </footer>
        </Form>
      </div>
    )
  }
}
export default ContactForm
